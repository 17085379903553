<template>
    <div>
        <!-- Label  -->
        <BaseLabel v-if="label" :for="INPUT_ID" :required="required">
            {{ label }}
        </BaseLabel>

        <div
            :class="[
                'relative flex h-10 overflow-hidden rounded-md shadow transition-shadow sm:h-9',
                isFocused ? 'ring-2 ring-blue-500' : 'ring-1 ring-gray-300',
                errorMessage && 'text-red-900 !ring-red-300',
                errorMessage && isFocused && '!ring-red-500',
                disabled && 'pointer-events-none cursor-not-allowed',
            ]"
        >
            <!-- Slot Prepend -->
            <slot name="prepend" />

            <div class="relative flex flex-grow items-center">
                <!-- Icon -->
                <div
                    v-if="slots.icon"
                    class="pointer-events-none absolute left-0 pl-2"
                >
                    <slot name="icon" />
                </div>

                <!-- Input -->
                <input
                    :id="INPUT_ID"
                    :value="modelValue"
                    :type="type"
                    :class="[
                        'text-align-inherit w-full truncate rounded-md border-none placeholder-gray-400 ring-0 focus:border-none focus:outline-none focus:ring-0 disabled:bg-slate-100 sm:text-sm',
                        errorMessage && 'placeholder-red-300',
                        slots.icon && 'pl-9',
                        (slots.append ||
                            loading ||
                            (clearable && modelValue)) &&
                            'pr-9',
                        hideNumberArrows && 'hide-arrows',
                        dark
                            ? 'bg-gray-700 text-white'
                            : 'bg-white text-gray-900',
                    ]"
                    :disabled="disabled"
                    :step="step"
                    :placeholder="placeholder"
                    :autocomplete="autocomplete"
                    :min="min"
                    @change="onChange"
                    @input="emit('input', $event)"
                    @focus="onFocus"
                    @blur="onBlur"
                    @keydown="onKeyPress"
                    v-on="nativeListeners"
                />

                <div
                    :class="[
                        'absolute inset-y-0 right-0 flex items-center pr-2',
                        errorMessage && 'right-6',
                    ]"
                >
                    <BaseProgressCircular
                        v-if="loading"
                        size="22"
                        class="text-primary-500 mr-1"
                        aria-hidden="true"
                    />

                    <button
                        v-else-if="modelValue && clearable"
                        tabindex="-1"
                        type="button"
                        class="h-full text-gray-400 transition-colors hover:text-gray-600"
                        @click.capture="emit('clear')"
                    >
                        <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>

                <!-- Error icon -->
                <Transition name="fade">
                    <div
                        v-if="errorMessage"
                        class="pointer-events-none absolute inset-y-0 right-2 flex items-center"
                    >
                        <ExclamationCircleIcon
                            class="h-5 w-5 text-red-500"
                            aria-hidden="true"
                        />
                    </div>
                </Transition>
            </div>

            <!-- Slot Append -->
            <slot name="append" />
        </div>

        <!-- Error state -->
        <Transition name="fade">
            <p
                v-if="errorMessage"
                class="mt-1 flex items-center text-xs text-red-500"
            >
                {{ errorMessage }}
            </p>
        </Transition>

        <div v-if="hint || $slots.hint" class="mt-2">
            <slot name="hint">
                <p v-if="hint" class="text-sm leading-5 text-gray-500">
                    {{ hint }}
                </p>
            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, useSlots } from 'vue'
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid'
import { nanoid } from 'nanoid/non-secure'
import { XMarkIcon } from '@heroicons/vue/24/outline'

type InputType = 'text' | 'email' | 'number' | 'password' | 'url'

const INPUT_ID = `input-${nanoid()}`

const props = defineProps({
    modelValue: {
        type: [String, Number, null] as PropType<string | number | null>,
        default: undefined,
    },
    type: {
        type: String as PropType<InputType>,
        default: 'text',
    },
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    hint: {
        type: String,
        default: '',
    },
    autocomplete: {
        type: String,
        default: 'off',
    },
    format: {
        type: Function as (...args: any[]) => any,
        default: undefined,
    },
    /** Required key for proper validation if component is used inside Form component */
    validation: {
        type: String,
        default: '',
    },
    blacklistedCharacters: {
        type: Array as PropType<string[]>,
        default: undefined,
    },
    hideNumberArrows: {
        type: Boolean,
        default: false,
    },
    /** Only works for input type=number */
    step: {
        type: Number,
        default: undefined,
    },
    /** Only works for input type=number */
    min: {
        type: Number,
        default: undefined,
    },
    required: {
        type: Boolean,
        default: false,
    },
    errorMessage: {
        type: String,
        default: '',
    },
    /** Listeners to pass to the native inner html input tag */
    nativeListeners: {
        type: Object as PropType<Record<string, (e: Event) => any>>,
        default: () => ({}),
    },
    clearable: {
        type: Boolean,
        default: false,
    },
    dark: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits<{
    (event: 'update:modelValue', value: string): void
    (event: 'input', payload: Event): void
    (event: 'clear'): void
    (event: 'focus'): void
    (event: 'blur'): void
}>()

const slots = useSlots()

const isFocused = ref(false)

function onChange(event: Event) {
    const val = (event.target as HTMLInputElement).value

    emit('update:modelValue', props.format ? props.format(val) : val)
}

function onKeyPress(e: KeyboardEvent) {
    if (props.blacklistedCharacters?.includes(e.key)) {
        e.preventDefault()
    }
}

function onFocus() {
    isFocused.value = true

    emit('focus')
}

function onBlur() {
    isFocused.value = false

    emit('blur')
}
</script>

<style lang="postcss" scoped>
/* Chrome, Safari, Edge, Opera */
.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.hide-arrows[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
}
</style>
